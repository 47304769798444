/* noto-sans-jp-100normal - japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Noto Sans Japanese Thin'),
    local('NotoSansJapanese-Thin'), 
    url('./files/noto-sans-jp-japanese-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-jp-japanese-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-jp-300normal - japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Noto Sans Japanese Light'),
    local('NotoSansJapanese-Light'), 
    url('./files/noto-sans-jp-japanese-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-jp-japanese-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-jp-400normal - japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Noto Sans Japanese Regular'),
    local('NotoSansJapanese-Regular'), 
    url('./files/noto-sans-jp-japanese-400.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-jp-japanese-400.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-jp-500normal - japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Noto Sans Japanese Medium'),
    local('NotoSansJapanese-Medium'), 
    url('./files/noto-sans-jp-japanese-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-jp-japanese-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-jp-700normal - japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Noto Sans Japanese Bold'),
    local('NotoSansJapanese-Bold'), 
    url('./files/noto-sans-jp-japanese-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-jp-japanese-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-jp-900normal - japanese */
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Noto Sans Japanese Black'),
    local('NotoSansJapanese-Black'), 
    url('./files/noto-sans-jp-japanese-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/noto-sans-jp-japanese-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
